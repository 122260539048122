<template>
  <div class="content">
    <PageHeader title="Optouts" />
    <section class="page-content container-fluid">
      <div class="row">
        <div class="col-md-6">
          <opted-card />
        </div>
        <div class="col-md-6">
          <keywords-card ref="keywords" />
        </div>
      </div>
    </section>
    <create-optouts-modal id="create-optouts-modal" @submit="fetchAll(1)" />
    <optouts-modal id="optouts-modal" @submit="fetchAll(1)" />
    <create-key-modal id="create-key-modal" @submit="fetchKeywords" />
    <key-modal id="key-modal" @submit="fetchKeywords" />
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import OptedCard from '@/components/whatsapp/optouts/OptedCard.vue';
import KeywordsCard from '@/components/whatsapp/optouts/KeywordsCard.vue';
import CreateKeyModal from '@/components/whatsapp/CreateKeyModal.vue';
import KeyModal from '@/components/whatsapp/KeyModal.vue';
import CreateOptoutsModal from '@/components/whatsapp/CreateOptoutsModal.vue';
import OptoutsModal from '@/components/whatsapp/OptoutsModal.vue';
import SmsService from '@/services/sms.service';
import Swal from 'sweetalert2';

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
    OptedCard,
    KeywordsCard,
    CreateOptoutsModal,
    OptoutsModal,
    CreateKeyModal,
    KeyModal,
  },
  data() {
    return {
      isSending: false,
      selectedTemplates: [],
    };
  },
  methods: {
    fetchKeywords() {
      this.$refs.keywords.fetch(1);
    },
    selectedTemplatesChange(param) {
      this.selectedTemplates = param.selectedTemplates;
    },
    deleteAll() {
      Swal.fire({
        title: this.$t('warnings.optouts.remove-number'),
        text: this.$t('warnings.optouts.text-number'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          const toDelete = [];
          this.selectedTemplates.forEach((item) => {
            toDelete.push(item.id);
          });
          this.isSending = true;
          SmsService.deleteAllOptouts({ ids: toDelete }).then(
            () => {
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: this.$t('warnings.optouts.removed-number'),
                type: 'success',
              });
              this.$root.$emit('updated.optout');
              this.isSending = false;
              this.selectedTemplates = [];
            },
            (error) => {
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                content: error.response.data.message,
                type: 'danger',
              });
              this.isSending = false;
            },
          );
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.btn i {
  line-height: 1;
  color: inherit;
}
</style>
